import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import '../styles/global.css';
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <main>
      <Helmet>
        <title>About me | {data.site.siteMetadata.title}</title>
      </Helmet>
      <Header data={data} />
      <div class="content flex">
        <div class="left">
          <h1>About me</h1>
          <p>Hi, my name is Nils Buschhüter. I’m a fullstack software developer and e-commerce expert from Düsseldorf, Germany. I'm passionate about creating and scaling websites and online shops using state of the art technologies and APIs. During the day, I work as product owner for an e-commerce website and recently founded my own company to help businesses grow by my expertise. I love to create digital products and build up high-performing e-commerce websites.</p>
          <p>My career started hanging around the internet in the late nineties. I started teaching myself how to make websites first as a hobby, later with a university education. </p>
          <p>I gained so much of my today’s experience through the open-source community, like personal blogs, tutorials and GitHub projects. By creating this website I also want to contribute back my experience to the community.</p>
          <p>If there's anything you want to talk about with me feel free to contact me at <a href="mailto:hello@aboutnils.com">hello@aboutnils.com</a>.</p>
        </div>
        <div class="right">
          <div class="photo">
            <Img fluid={data.profilePic.childImageSharp.fluid} style={{borderRadius: "50%"}} />
          </div>
        </div>
      </div>
    </main>
  );
}

export const query = graphql`
  query {
    profilePic: file(relativePath: { eq: "nils.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      siteMetadata {
        title
      }
    }
  }
`
